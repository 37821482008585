<template>
  <div class="bg-alDark font-raleway font-light">
    <div class="wrapper pt-24 pb-64 min-h-screen max-w-screen-2xl m-auto">
      <router-link to="/">
        <div class="text-alAccent text-right pr-12 pl-12">
          <div class="text-4xl sm:text-5xl xl:text-6xl">Agentur</div>
          <div class="text-4xl sm:text-5xl xl:text-6xl">Annika Lipski</div>
        </div>
      </router-link>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "al-wrapper",
};
</script>
