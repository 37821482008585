<template>
  <div class="bg-lipDark font-raleway font-light">
    <div class="wrapper p-6 pt-24 pb-36 max-w-screen-xl m-auto">
      <img src="../assets/limo.png" alt="Annika Lipski Immobilien" />

      <div class="mt-24 text-2xl lg:text-4xl text-white">
        Ihre Ansprechpartnerin in der Uckermark.
      </div>

      <div class="mt-24">
        <a href="https://lipski-immobilien.de">
          <button
            class="
              p-4
              pl-16
              pr-16
              w-full
              lg:w-auto
              rounded-md
              text-white
              font-bold
              bg-lipMain
            "
          >
            Weitere Informationen
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "limo-landing-block",
};
</script>

<style scoped>
img {
  width: 400px;
  max-width: 100%;
}
</style>
