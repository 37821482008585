import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import "./assets/tailwind.css";
import LandingView from "@/views/LandingView";
import Impressum from "@/views/Impressum";
import Datenschutz from "@/views/Datenschutz";

const routes = [
  { path: "/", component: LandingView },
  { path: "/impressum", component: Impressum },
  { path: "/datenschutz", component: Datenschutz },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.mount("#app");
