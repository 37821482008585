<template>
  <div class="bg-white font-raleway font-light">
    <div class="wrapper p-6 pt-24 lg:pb-0 pb-24 max-w-screen-xl m-auto">
      <img
        class="logo"
        src="../assets/mbv.png"
        alt="Mecklenburgische Versicherungsgruppe"
      />

      <div class="mt-24 text-2xl">Bezirks-Generalvertretung</div>
      <div class="mt-6 text-3xl font-bold">Annika Lipski</div>

      <div class="mt-24">
        <a href="https://www.mecklenburgische.de/annika-lipski/templin">
          <button
            class="
              p-4
              pl-16
              pr-16
              w-full
              lg:w-auto
              rounded-md
              text-white
              font-bold
              bg-mbvMain
            "
          >
            Weitere Informationen
          </button>
        </a>
      </div>
      <!--        <div class="lg:col-start-3 relative">-->
      <!--          <img-->
      <!--            class="image-building"-->
      <!--            src="../assets/aal-building.jpeg"-->
      <!--            alt="Agentur Lipski Building"-->
      <!--          />-->
      <!--        </div>-->
    </div>

    <div
      class="
        grid grid-cols-1
        lg:grid-cols-3
        gap-10
        max-w-screen-xl
        m-auto
        low-building-grid-height
      "
    >
      <div
        class="bg-lipDark lg:bg-transparent relative lg:col-start-3 lg:-my-8"
      >
        <div
          aria-hidden="true"
          class="bg-white absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
        />
        <div
          class="
            bg-lipDark
            lg:bg-transparent
            mx-auto
            px-4
            sm:max-w-xl sm:px-6
            lg:p-0 lg:h-full
          "
        >
          <div
            class="
              aspect-w-1 aspect-h-1
              max-w-md
              m-auto
              rounded-xl
              shadow-xl
              overflow-hidden
              lg:aspect-none lg:h-full
            "
          >
            <img
              class="object-cover lg:h-full lg:w-full"
              src="../assets/aal-building.jpeg"
              alt="Annika Lipski"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mbv-landing-block",
};
</script>

<style scoped>
.logo {
  width: 400px;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .low-building-grid-height {
    height: 100px;
  }
}
</style>
