<template>
  <div class="bg-alDark font-raleway font-light">
    <div
      class="
        wrapper
        text-center text-alLight text-sm
        p-12
        max-w-screen-xl
        m-auto
      "
    >
      <div class="mb-3">
        <router-link to="/impressum">Impressum</router-link>
        |
        <router-link to="/datenschutz">Datenschutz</router-link>
      </div>
      <div>&copy; 2023 Annika Lipski Immobilien</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "al-footer",
};
</script>

<style scoped></style>
