<template>
  <al-wrapper>
    <div class="text-alLight p-8">
      <h1 class="text-alAccent mt-24">Impressum</h1>

      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        Annika Lipski<br />
        Immobilienmaklerin<br />
        Puschkinstr. 8<br />
        17268 Templin
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: +49 3987 4398588<br />
        Telefax: +49 3987 409946<br />
        E-Mail: kontakt@lipski-immobilien.de
      </p>

      <h2>Umsatzsteuer-ID</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
        DE277960445
      </p>

      <h2>Aufsichtsbehörde</h2>
      <p>
        Verbraucherschutzamt - Gewerbe und Ordnungsangelegenheiten in
        Gerswalde<br />
        Amt Gerswalde<br />
        Dorfmitte 14A<br />
        17268 Gerswalde
      </p>
      <p>
        <a
          href="https://www.amt-gerswalde.de/"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.amt-gerswalde.de/</a
        >
      </p>

      <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
      <p>
        Berufsbezeichnung:<br />
        Immobilienmaklerin
      </p>
      <p>Zuständige Kammer:<br />IHK</p>
      <p>
        Verliehen in:<br />
        Deutschland
      </p>
      <p>Es gelten folgende berufsrechtliche Regelungen:</p>
      <h2>Angaben zur Berufs­haftpflicht­versicherung</h2>
      <p>
        <strong>Name und Sitz des Versicherers:</strong><br />
        ERGO Group AG<br />
        Victoriaplatz 2<br />
        40198 Düsseldorf
      </p>
      <p><strong>Geltungsraum der Versicherung:</strong><br />EU</p>

      <h2>Redaktionell verantwortlich</h2>
      <p>
        Annika Lipski<br />
        Puschkinstr. 8<br />
        17268 Templin
      </p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
          >https://ec.europa.eu/consumers/odr/</a
        >.<br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  </al-wrapper>
  <al-footer></al-footer>
</template>

<script>
import AlWrapper from "@/components/al-wrapper";
import AlFooter from "@/components/al-footer";
export default {
  components: { AlFooter, AlWrapper },
};
</script>

<style scoped>
h1 {
  font-size: 32px;
}

h2 {
  font-size: 26px;
}

p,
h1,
h2 {
  margin-bottom: 24px;
}
</style>
