<template>
  <al-wrapper>
    <div class="mt-36 pb-16 bg-alLight lg:pb-0 lg:z-10 lg:relative">
      <div
        class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-4 lg:gap-8"
      >
        <div class="relative lg:-my-8">
          <div
            aria-hidden="true"
            class="absolute inset-x-0 top-0 h-1/2 bg-alDark lg:hidden"
          />
          <div
            class="mx-auto max-w-sm px-4 sm:max-w-xl sm:px-6 lg:p-0 lg:h-full"
          >
            <div
              class="
                aspect-w-1 aspect-h-1
                max-w-md
                m-auto
                rounded-xl
                shadow-xl
                overflow-hidden
                lg:aspect-none lg:h-full
              "
            >
              <img
                class="object-cover lg:h-full lg:w-full"
                src="../assets/annika_lipski.jpeg"
                alt="Annika Lipski"
              />
            </div>
          </div>
        </div>
        <div class="mt-12 lg:m-0 lg:col-span-3 lg:pl-8">
          <div
            class="
              mx-auto
              max-w-md
              px-4
              sm:max-w-2xl sm:px-6
              lg:px-0 lg:py-20 lg:max-w-none
            "
          >
            <div
              class="text-alDark text-center text-4xl lg:text-left lg:text-7xl"
            >
              Alles unter einem Dach!
            </div>
            <div class="text-alDark mt-12 text-center lg:text-left text-2xl">
              Egal ob Versicherungen oder Immobilien, wir beraten Sie gern!
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-36 m-auto" style="max-width: 70%">
      <div class="flex flex-col gap-20 lg:flex-row flex-wrap">
        <div class="info-block flex-1 border-l-2 border-alAccent p-3">
          <div class="text-alAccent text-4xl mb-3">Wo?</div>
          <div class="text-alLight text-2xl">
            <address class="pt-6">Puschkinstraße 8<br />17268 Templin</address>
            <!-- todo: link google maps / Apple Maps -->
          </div>
        </div>
        <div class="info-block flex-1 border-l-2 border-alAccent p-3">
          <div class="text-alAccent text-4xl mb-3">Heute geöffnet?</div>
          <div class="text-alLight mb-6">
            Immobilienbüro: <br />
            {{ getsLimoOpeningHours() }}
          </div>
          <div class="text-alLight mb-6">
            Versicherungsbüro: <br />
            {{ getsMbvOpeningHours() }}
          </div>
          <div @click="open = true" class="cursor-pointer text-alAccent mt-3">
            Alle Öffnungszeiten anzeigen
          </div>
        </div>
      </div>
    </div>
  </al-wrapper>
  <mbv-landing-block></mbv-landing-block>
  <limo-landing-block></limo-landing-block>
  <al-footer></al-footer>

  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open = false"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-alDark
              text-alLight
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
            "
          >
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  class="text-2xl leading-6 mb-12 text-alAccent"
                >
                  Öffnungszeiten
                </DialogTitle>
                <div class="mt-2 text-left">
                  <div class="text-2xl text-alAccent">Immobilienbüro</div>
                  <div class="mt-2 font-medium">Dienstag:</div>
                  <div>09:00 - 12:00 Uhr und 13:00 - 18:00 Uhr</div>
                  <div class="mt-2 font-medium">Donnerstag:</div>
                  <div>09:00 - 12:00 Uhr</div>
                </div>
                <div class="mt-12 text-left">
                  <div class="text-2xl text-alAccent">Versicherungsbüro</div>
                  <div class="mt-2 font-medium">Montag:</div>
                  <div>08:00 - 12:00 Uhr und 13:00 - 15:00 Uhr</div>
                  <div class="mt-2 font-medium">Dienstag:</div>
                  <div>08:00 - 12:00 Uhr und 13:00 - 18:00 Uhr</div>
                  <div class="mt-2 font-medium">Mittwoch:</div>
                  <div>08:00 - 12:00 Uhr und 13:00 - 15:00 Uhr</div>
                  <div class="mt-2 font-medium">Donnerstag:</div>
                  <div>08:00 - 12:00 Uhr und 13:00 - 17:00 Uhr</div>
                  <div class="mt-2 font-medium">Freitag:</div>
                  <div>08:00 - 12:00 Uhr</div>
                </div>
              </div>
            </div>
            <div class="mt-6 sm:mt-12">
              <button
                type="button"
                class="
                  inline-flex
                  justify-center
                  w-full
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-alLight
                  text-base
                  font-medium
                  text-alDark
                  sm:text-sm
                "
                @click="open = false"
              >
                Schließen
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ref } from "vue";

import MbvLandingBlock from "@/components/mbv-landing-block";
import LimoLandingBlock from "@/components/limo-landing-block";
import AlFooter from "@/components/al-footer";
import AlWrapper from "@/components/al-wrapper";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    AlWrapper,
    AlFooter,
    LimoLandingBlock,
    MbvLandingBlock,
  },
  setup() {
    const open = ref(false);

    return {
      open,
    };
  },
  methods: {
    getsMbvOpeningHours() {
      const d = new Date();
      let day = d.getDay();
      switch (day) {
        default:
        case 6:
        case 0:
          return "Nach Vereinbarung";
        case 1:
          return "08:00 - 12:00 Uhr und 13:00 - 15:00 Uhr";
        case 2:
          return "08:00 - 12:00 Uhr und 13:00 - 18:00 Uhr";
        case 3:
          return "08:00 - 12:00 Uhr und 13:00 - 15:00 Uhr";
        case 4:
          return "08:00 - 12:00 Uhr und 13:00 - 17:00 Uhr";
        case 5:
          return "08:00 - 12:00 Uhr";
      }
    },
    getsLimoOpeningHours() {
      const d = new Date();
      let day = d.getDay();
      switch (day) {
        default:
        case 6:
        case 0:
        case 1:
        case 3:
        case 5:
          return "Nach Vereinbarung";
        case 2:
          return "09:00 - 12:00 Uhr und 13:00 - 18:00 Uhr";
        case 4:
          return "09:00 - 12:00 Uhr";
      }
    },
  },
};
</script>

<style>
.info-block {
  min-height: 180px;
}
</style>
